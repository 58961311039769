import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import config from "../../../config";
import api from "../../../api";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from "@mui/material";
import { style, textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
// import useAuth from "../../function/useAuth";
const theme = createTheme();
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { WatDatePicker } from "thaidatepicker-react";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

const WorkingTime = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [idCard, setIdCard] = useState("");
  const [gsbNumber, setGsbNumber] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openWarning, setOpenWarning] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openFirstTimeLogin, setOpenFirstTimeLogin] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  //   const localizer = momentLocalizer(moment);
  const [eventCalendar, setEventCalendar] = useState([]);
  const [textCalendarCreated, setTextCalendarCreated] = useState("");
  const [textCalendar, setTextCalendar] = useState("");
  const [textCalendarStart, setTextCalendarStart] = useState("");
  const [textCalendarEnd, setTextCalendarEnd] = useState("");

  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dateWork, setDateWork] = useState(new Date());
  const [detailWork, setDetailWork] = useState("");
  const [hourWork, setHourWork] = useState(null);
  const [timeStart, setTimeStart] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);
  const [error, setError] = useState(null);
  const localizer = momentLocalizer(moment);

  const closeCalendarEvent = () => {
    setOpenCalendar(false);
    setTextCalendar("");
    setTextCalendarStart("");
    setTextCalendarEnd("");
  };

  const initialHoursList = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minute = i % 2 === 0 ? "00" : "30";
    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
    return {
      name: `${formattedHour}:${minute}`,
      value: `${formattedHour}:${minute}`,
    };
  });

  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) =>
      String(value).includes(searchTerm)
    );
  });

  const paginatedRows = filteredRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const initialMinutesList = Array.from({ length: 60 }, (_, i) => {
    const minute = i < 10 ? `0${i}` : `${i}`;
    return { name: minute, value: minute };
  });
  const [hoursList, sethoursList] = useState(initialHoursList);
  const [minutesList, setminutesList] = useState(initialMinutesList);
  const columns = [
    {
      id: "detail",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          <IconButton onClick={() => handleCheckForm(row)}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
    { id: "order", label: "ลำดับ", minWidth: 100 },
    { id: "detail", label: "รายละเอียดการทำงาน", minWidth: 100 },
    { id: "start", label: "เวลาเริ่มต้น", minWidth: 100 },
    { id: "end", label: "เวลาสิ้นสุด", minWidth: 100 },
    { id: "created", label: "วันที่ยื่น", minWidth: 100 },
    { id: "status", label: "สถานะ", minWidth: 100 },
    {
      id: "delete",
      label: "",
      minWidth: 100,
      render: (row) => (
        <>
          {/* <IconButton aria-label="approve" onClick={(() => navigate('/loan/form'), { state: { params: row.code } })}> */}
          <IconButton onClick={() => handleClickOpenDelete(row)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleCloseOpenWarning = () => {
    setOpenWarning(false);
  };

  const handleCloseOpenFirstTimeLogin = () => {
    setOpenFirstTimeLogin(false);
  };

  function handleCheckForm(row) {
    console.log("row", row);
    setOpenCalendar(true);
    setTextCalendarCreated(row.created);
    setTextCalendar(row.detail);
    setTextCalendarStart(row.start);
    setTextCalendarEnd(row.end);
    // setOpenCalendar(row);
  }

  const handleSubmitSetNewPassword = () => {
    console.log("eiei");
    setIsLoading(true);
    console.log("user.username", user.username);
    console.log("newPassword", newPassword);
    api
      .post(`${config.mainAPI}/updateNewPasswordFirstTimeLogin`, {
        username: user.username,
        password: newPassword,
      })
      .then(function (response) {
        setOpenFirstTimeLogin(false);
        setIsLoading(false);
        localStorage.removeItem("user_data");
        localStorage.removeItem("userData");
        localStorage.removeItem("isLoggedIn");
        window.location.reload();
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // useAuth();

  function searchData() {
    if (type) {
      axios
        .post(`${config.mainAPI}/searchDataInformation`, {
          name: name,
          idCard: idCard,
          type: type,
          gsbNumber: gsbNumber,
          role: user.role,
          debtCenter: user.debtCenter,
        })
        .then(function (response) {
          const value = response.data.data;
          console.log("value searchData", value);
          setList(value);
          setRows(
            value.map((item, index) =>
              createData(
                index + 1,
                item.defendant_fullname,
                item.defendant_type,
                item.document_code_gen
              )
            )
          );
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    } else {
      // Error
      setOpenWarning(true);
    }
  }

  const closePopupCreate = () => {
    setError(null);
    setDateWork(new Date());
    setTimeStart(null);
    setTimeEnd(null);
    setDetailWork(null);
    setOpenCreate(false);
  };

  const addWorkTime = () => {
    setOpenCreate(false);
  };

  function handleClickOpenDelete(row) {
    setCode(row.id);
    setOpenDelete(true);
  }

  function deleteData() {
    let payload = {
      id: code,
    };
    api
      .post(`${config.mainAPI}/deletetWorkingTime`, { ...payload })
      .then((response) => {
        const value = response?.data.data;
        console.log("value", value);
        openDelete(false);
        setCode("");
        getWorkingTime(user);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // const filteredRows = rows.filter((row) => {
  //   return Object.values(row).some((value) => {
  //     return String(value).toLowerCase().includes(searchTerm.toLowerCase());
  //   });
  // });

  function createData(order, detail, start, end, created, status, id) {
    const formattedDateStart = moment(start)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm");

    const formattedDateEnd = moment(end)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm");

    const formattedDateCreated = moment(created)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm");
    return {
      order,
      detail,
      start: start ? formattedDateStart : "-",
      end: end ? formattedDateEnd : "-",
      created: created ? formattedDateCreated : "-",
      status,
      id,
    };
  }

  const getDataIntoCalendar = (user) => {
    let payload = {
      username: user.username,
    };
    api
      .post(`${config.mainAPI}/listWorkingTimeByUser`, { ...payload })
      .then((response) => {
        const value = response?.data.data;
        console.log("value", value);
        value.map((item) => {
          const dateStart = new Date(item.working_started);
          const dateEnd = new Date(item.working_end);
          const hoursStart = moment(item.working_started).format("HH");
          const minutesStart = moment(item.working_started).format("mm");
          const hoursEnd = moment(item.working_end).format("HH");
          const minutesEnd = moment(item.working_end).format("mm");
          dateStart.setHours(hoursStart);
          dateStart.setMinutes(minutesStart);
          dateEnd.setHours(hoursEnd);
          dateEnd.setMinutes(minutesEnd);

          let sum = {
            title: `ลงเวลาการทำงาน`,
            start: dateStart,
            end: dateEnd,
            details: `${item.working_detail}`,
          };
          setEventCalendar((item1) => [...item1, sum]);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function getWorkingTime(userData) {
    // listWorkingTimeRequest
    let payload = {
      username: userData.username,
    };
    api
      .post(`${config.mainAPI}/listWorkingTimeRequest`, { ...payload })
      .then((response) => {
        const value = response?.data.data;
        console.log("value", value);
        setRows(
          value.map((item, index) => {
            return createData(
              index + 1,
              item.working_detail,
              item.working_started,
              item.working_end,
              item.created_date,
              item.working_status,
              item.id
            );
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleCheckDetail(row) {
    if (row.type == "สินเชื่อ") {
      navigate("/loan/detail", { state: { params: row.code } });
    } else if (row.type == "ขับไล่") {
      navigate("/expal/detail", { state: { params: row.code } });
    } else if (row.type == "บัตรเครดิต") {
      navigate("/credit/detail", { state: { params: row.code } });
    }
  }

  const saveData = () => {
    const dateWorkShow = moment(dateWork).format("YYYY-MM-DD");
    const timeStartShow = timeStart;
    const timeEndShow = timeEnd;
    let body = {
      date: dateWork
        ? moment(dateWorkShow, "YYYY-MM-DD").format("YYYY-MM-DD")
        : null,
      start: timeStart
        ? moment(`${dateWorkShow}T${timeStartShow}`, "YYYY-MM-DDTHH:mm").format(
            "YYYY-MM-DD HH:mm"
          )
        : null,
      end: timeEnd
        ? moment(`${dateWorkShow}T${timeEndShow}`, "YYYY-MM-DDTHH:mm").format(
            "YYYY-MM-DD HH:mm"
          )
        : null,
      detail: detailWork,
      user: user.username,
    };
    api
      .post(`${config.mainAPI}/createtWorkingTime`, body)
      .then(function (response) {
        setIsLoading(false);
        if (response.data.success) {
          closePopupCreate();
        } else {
          setError(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setUser(userData);
      getWorkingTime(userData);
    }
  }, []);

  const handleClickOpenCreate = () => {};

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: isSelected ? "red" : event.color,
    };
    return {
      style,
    };
  };

  const handleSelectSlot = () => {
    console.log("1111");
  };

  const handleDatePickerChange = (setData, christDate, buddhistDate) => {
    setError(null);
    setData(christDate);
  };

  const openCalendarEvent = (event) => {
    console.log("event", event);
    setOpenCalendar(true);
    const text = `รายละเอียด: ${event.title} `;
    const textStart = `เริ่ม: ${moment(event.start)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm")}`;
    const textEnd = `สิ้นสุด: ${moment(event.end)
      .locale("th")
      .add(543, "year")
      .format("DD/MM/YYYY HH:mm")}`;
    console.log("textStart", textStart);
    console.log("textEnd", textEnd);
    setTextCalendar(text);
    setTextCalendarStart(textStart);
    setTextCalendarEnd(textEnd);
  };

  const exportReport = () => {
    console.log("localizer", localizer);
  };

  return (
    <div>
      <Card sx={{ minWidth: 275, minHeight: "100vh" }}>
        <Typography
          variant="h3"
          sx={{ fontWeight: 500, textAlign: "center", mt: 3 }}
        >
          ระบบลงเวลาการทำงาน
        </Typography>
        <div>
          <Box
            sx={{
              alignItems: "center",
              textAlign: "right",
              marginLeft: 3,
              marginTop: 3,
            }}
          >
            {/* <Button
              variant="outlined"
              sx={{
                marginRight: "20px",
                marginTop: "10px",
                marginBottom: "10px",
                color: "#000",
                borderColor: "#000",
              }}
              color="primary"
              startIcon={<FileOpenIcon />}
              onClick={() => exportReport()}
            >
              ออกรายงาน
            </Button> */}

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenCreate(true)}
              sx={{
                marginRight: "20px",
                marginTop: "10px",
                marginBottom: "10px",
                color: "#000",
                borderColor: "#000",
              }}
              startIcon={<AddCircleIcon />}
            >
              ลงเวลาการทำงาน
            </Button>
          </Box>
        </div>

        <Box sx={{ padding: 5 }}>
          {/* <Calendar
            localizer={localizer}
            events={eventCalendar}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 700 }}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={(event) => openCalendarEvent(event)}
            onSelectSlot={handleSelectSlot}
          /> */}

          {/* <Calendar
            localizer={localizer}
            events={eventCalendar}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 700 }}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={(event) => openCalendarEvent(event)}
          /> */}

          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              padding: "30px",
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody> */}
                <TableBody>
                  {paginatedRows.map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        {/* <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.order}>
                      {columns.map((column) => (
                        <TableCell key={column.id} align="center">
                          {column.render ? column.render(row) : row[column.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper> */}
      </Card>

      <Dialog open={openCreate} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            รายละเอียดลงเวลาการทำงาน
          </Typography>
          <Typography
            sx={{ mb: 3, fontSize: 16, mt: 3 }}
            textAlign="center"
          ></Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>วันที่:</Typography>

              <WatDatePicker
                className="custom-date-picker"
                value={dateWork}
                onChange={(christDate, buddhistDate) =>
                  handleDatePickerChange(setDateWork, christDate, buddhistDate)
                }
                dateFormat={"yyyy-MM-dd"}
                displayFormat={"DD/MM/YYYY"}
                clearable={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>เวลาเริ่ม:</Typography>
              <Select
                value={timeStart}
                onChange={(e) => setTimeStart(e.target.value)}
                variant="outlined"
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                {hoursList &&
                  hoursList.map((column) => (
                    <MenuItem key={column.value} value={column.value}>
                      {column.value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ fontSize: "16px" }}>เวลาสิ้นสุด:</Typography>
              <Select
                value={timeEnd}
                onChange={(e) => setTimeEnd(e.target.value)}
                variant="outlined"
                fullWidth
                color="dark"
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
              >
                {hoursList &&
                  hoursList.map((column) => (
                    <MenuItem key={column.value} value={column.value}>
                      {column.value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: "16px" }}>รายละเอียด:</Typography>
              <TextField
                type="text"
                color="dark"
                fullWidth
                sx={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                }}
                value={detailWork}
                onChange={(e) => setDetailWork(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => closePopupCreate()}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                disabled={error}
                onClick={() => saveData()}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Dialog open={openCalendar} fullWidth={true} maxWidth={"md"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            รายละเอียดลงเวลาการทำงาน
          </Typography>
          <Typography
            sx={{ mb: 3, fontSize: 16, mt: 3 }}
            textAlign="center"
          ></Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: "16px" }}>วันที่:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: "16px" }}>
                    {textCalendarCreated}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: "16px" }}>เวลาเริ่ม:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: "16px" }}>
                    {textCalendarStart}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: "16px" }}>
                    เวลาสิ้นสุด:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: "16px" }}>
                    {textCalendarEnd}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography sx={{ fontSize: "16px" }}>
                      รายละเอียด:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ fontSize: "16px" }}>
                      {textCalendar}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={closeCalendarEvent}
                sx={{ marginRight: 2 }}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openDelete} fullWidth={true} maxWidth={"xs"}>
        <DialogContent>
          <Typography sx={{ fontWeight: 700, fontSize: 20 }} textAlign="center">
            ยืนยันการลบ
          </Typography>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6} sm={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setOpenDelete(false);
                  setCode("");
                }}
                sx={{ marginRight: 2 }}
              >
                ยกเลิก
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                disabled={error}
                onClick={() => deleteData()}
              >
                บันทึก
              </Button>
            </Grid>
          </Grid>

          {error ? (
            <>
              <Alert sx={{ marginTop: 5 }} open={error} severity="error">
                {error}
              </Alert>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default WorkingTime;
